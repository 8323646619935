import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from '@core';
import {
  Card,
  DealsReviewCard,
  DealsTimerRedesigned,
  SectionContainerSC as SectionContainer,
  Text,
} from '@components';
import { CardsBlockSection } from '@containers';
import { CrossedSpan } from '@containers/checkout/components';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import { isClient } from '@shared/helpers';
import { usePlansValuesContext } from '@context';
import { ONE_MONTH_PLAN } from '@shared/constants/checkout';

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 300px;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.backgroundColor} 0%, ${theme.colors.footerGradientColor} 100%)`};
`;

const DealsFooter = ({ data, showTimer = true }) => {
  const { t } = useTranslation();

  const {
    isLoading,
    suggestedPlan: plan,
    suggestedPlan: { discountPercentage },
  } = usePlansValuesContext();

  const { title, description, reviews } = data({ discountPercentage, t });

  return isClient && !isLoading && plan ? (
    <SectionContainer
      data-section-id="DealsFooter"
      containerProps={{
        overflow: 'hidden',
        position: 'relative',
      }}
      title={title}
      description={description}
    >
      <StyleSheetManager stylisPlugins={[]}>
        <Gradient />
      </StyleSheetManager>
      <Box px={16}>
        <Box maxWidth={432} m="auto">
          <Card.CheckoutCard
            isActive
            isHighlighted
            title={
              <Trans
                i18nKey="plans:checkoutCard.title"
                values={{
                  title: t(`plans:checkoutCard.periodTitle.${plan.subscriptionPeriod}`),
                  extraComment: plan.extraTime && t(`plans:monthsExtra.${plan.extraTime}`),
                }}
                components={[
                  <Text.Body2
                    as="span"
                    $fontWeight="inherit"
                    $fontSize="inherit"
                    $lineHeight="inherit"
                    color="primary"
                    whiteSpace="nowrap"
                    key={0}
                  />,
                ]}
              />
            }
            billingDescription={
              <Trans
                i18nKey={
                  plan.regularPrice
                    ? 'plans:checkoutCard.billingDescription'
                    : 'plans:checkoutCard.billingDescriptionWithoutRegularPrice'
                }
                values={{
                  price: plan.price,
                  regularPrice: plan.regularPrice,
                  currency: plan.currency,
                  periodicity: plan.regularPrice
                    ? t(`plans:checkoutCard.periodicity.${plan.subscriptionPeriod}`)
                    : t(`plans:checkoutCard.noDiscountPeriodicity.${plan.subscriptionPeriod}`),
                }}
                components={[<CrossedSpan color="darkBlue_40" key={0} />]}
              />
            }
            price={plan.pricePerMonth}
            subscriptionPeriod={plan.subscriptionPeriod}
            currency={plan.currency}
            pricePeriod={t('plans:checkoutCard.perMonthShort')}
            stickerLabel={
              plan.discountPercentage
                ? t('plans:checkoutCard.saveDiscount', {
                    discountPercentage: plan.discountPercentage,
                  })
                : null
            }
            couponText={
              plan.coupon && (
                <Trans
                  i18nKey="plans:checkoutCard.couponCode"
                  values={{
                    couponCode: plan.coupon,
                  }}
                  components={[<span key={0} />]}
                />
              )
            }
            button={{
              title: t('common:actions.getAtlasVpn'),
              buttonProps: {
                customEvent: { eventName: GA4_EVENTS.footerPricingCtaClick },
              },
            }}
            highlightLabel={
              <Text.Body4Strong mx={{ _: 24, xl: 36 }}>
                {plan.subscriptionPeriod === ONE_MONTH_PLAN
                  ? // t('plans:checkoutCard.savingsAndRefund14')
                    t('common:14dayMoneyBackGuarantee') // TODO Replace this line with 128th one, when saving common:14dayMoneyBackGuarantee is translated
                  : t('plans:checkoutCard.savingsAndRefund')}
              </Text.Body4Strong>
            }
          />
        </Box>
      </Box>

      {showTimer && (
        <Box position="relative" display="flex" justifyContent="center" mt={32}>
          <DealsTimerRedesigned />
        </Box>
      )}

      {reviews && (
        <Box maxWidth={1080} mx={{ lg: 'auto' }} mt={{ lg: 24 }} mb={{ sm: 24 }}>
          <CardsBlockSection
            {...reviews}
            itemComponent={(props) => (
              <Box p={16}>
                <DealsReviewCard {...props} isWithLink={reviews.isWithLink} />
              </Box>
            )}
            itemsOnDesktop={3}
            sliderOnDesktop={true}
            sliderProps={{
              onStepChange: () => trackCustomGA4Event({ eventName: GA4_EVENTS.footerReviewsClick }),
            }}
          />
        </Box>
      )}
    </SectionContainer>
  ) : null;
};

export default DealsFooter;
